.list-group-item {
    list-style-type: none;
  }
  
  .completed span {
    text-decoration: line-through;
    color: #aaa;
  }
  
  .input-container {
    display: flex;
    justify-content: space-between;
  }
  
  .input-container input {
    flex: 1;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for opacity */
    z-index: 1050; /* Ensure this is above the modal */
  }
  .login-container {
    position: relative;
   
}

.tint-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.2;
    height: 100%;
    background: linear-gradient(135deg, rgb(229, 250, 1), rgba(71, 162, 236, 0.7)); /* Gradient colors */
    overflow: hidden; 
    
    
}
.login-card {
  margin-top: 10%; /* Adjust this value as needed */
}
.todo-list {
	max-height: calc(6 * 3.5rem); /* Adjust the height based on the number of tasks */
	overflow-y: auto;
	border: 1px solid #ccc; /* Add border around the scrollable area */
	
	border-radius: 5px; /* Optional: round the corners of the border */
}

/* Hide the scrollbar */
.todo-list::-webkit-scrollbar {
	display: none; /* Chrome, Safari and Opera */
}

.todo-list {
	-ms-overflow-style: none; /* Internet Explorer and Edge */
	scrollbar-width: none; /* Firefox */
}
.todo-description {
  position: flex;
  max-width: 100%; /* Adjust the max-width as needed */
  overflow: hidden;
  overflow-wrap: break-word;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
}
